import insertHtmlAndExecuteScripts  from "./insertHtmlAndExecuteScripts";

export default (domElement: HTMLElement, url: string, options: any) => {
    
    if(!domElement || !url) {
        console.warn("Invalid parameters for function insertRemoteHtml");
        console.warn("domElement", domElement);
        console.warn("url", url);
        console.warn("options", options);
        return;
    }

    const fetchOptions = {
        ...{
            method: "get"
        },
        ...options
    }
    return fetch(url, fetchOptions)
        .then((response: Response) => {
            if(!response.ok){
                throw Error(`with status '${response.status}'`)   
              }      
              return response.text()
        })
        .then((htmlString: string) => insertHtmlAndExecuteScripts(domElement, htmlString))
        .catch((status: string) => console.error("Request error in insertRemoteHtml request: ", status))
}