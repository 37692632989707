import { createPublicRestClient } from "~/helper/restApi";

const validationRestClient = createPublicRestClient("validation");

const isEmailValid = (email: string) =>
  validationRestClient.post("/email/isValid", email, {
    headers: {
      "Content-Type": "text/plain"
    }
  });

export const isValid = (email: string) => isEmailValid(email);
