import { createPublicRestClient } from "~/helper/restApi";

const fileRestClient = createPublicRestClient("file");

const upload = (domFileInput: HTMLInputElement,
    fileName: string,
    mimetypes: string,
    uploadConfigId: string,
    tempfileIds: Array<number>) =>
    fileRestClient.post("/", {
        "file": domFileInput,
        "fileName": fileName,
        "acceptedMimetypesFromRequest": mimetypes,
        "uploadConfigId": uploadConfigId,
        "tempFileIds": tempfileIds
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    });

const uploadImage = (domFileInput: HTMLInputElement,
    fileName: string,
    mimetypes: string,
    uploadConfigId: string,
    tempfileIds: Array<number>) =>
    fileRestClient.post("/image", {
        "file": domFileInput,
        "fileName": fileName,
        "acceptedMimetypesFromRequest": mimetypes,
        "uploadConfigId": uploadConfigId,
        "tempFileIds": tempfileIds
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    });

export const uploadFile = (domFileInput: HTMLInputElement,
    fileName: string,
    mimetypes: string,
    uploadConfigId: string,
    tempfileIds: Array<number>) => upload(domFileInput,
    fileName,
    mimetypes,
    uploadConfigId,
    tempfileIds);
export const uploadFileImage = (domFileInput: HTMLInputElement,
    fileName: string,
    mimetypes: string,
    uploadConfigId: string,
    tempfileIds: Array<number>) => uploadImage(domFileInput,
    fileName,
    mimetypes,
    uploadConfigId,
    tempfileIds);