interface IDelegateEventConfig {
  eventName: string;
  domElement: HTMLElement | HTMLDocument;
  selector: string | undefined;
  callback: (event: Event) => {} | undefined;
}

const isCallbackAvailable = (callback: (event: Event) => {}) => {
  return typeof callback === "function";
};

const isSelectorAvailable = (selector: string) => {
  return typeof selector === "string" && selector != "";
};

export default (
  config: IDelegateEventConfig = {
    eventName: "click",
    domElement: document,
    selector: undefined,
    callback: undefined
  }
) => {
  const { eventName, domElement, selector, callback } = config;

  if (!isSelectorAvailable(selector) || !isCallbackAvailable(callback)) {
    console.warn("Invalid parameters for function delegateEvent");
    console.warn("selector", selector);
    console.warn("callback", callback);
    return;
  }

  domElement.addEventListener(eventName, (event: Event) => {
    const targetElement = event.target as HTMLElement;
    if (!targetElement) return;

    const isValidTargetElement = targetElement.matches(selector) || targetElement.closest(selector);
    if (isValidTargetElement) callback(event);
  });
};
