import axios from "axios";

export const createRestClientByPath = (path: string) => {
  const host = window.location.origin;
  return axios.create({
    baseURL: `${host}/${path}`
  });
};

export const createPublicRestClient = (restClient: string) => {
  return createRestClientByPath(`persis/remote/public/${restClient}`);
};
